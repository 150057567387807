<template>
  <b-card>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Listener Log</h4>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Bundle Name" label-for="bundle_name_filter">
          <b-form-select
            id="bundle_name_filter"
            v-model="filterValue"
            :options="filterList"
            @change="handleChangeFilter"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <!-- Filter UserName -->
    </b-row>
    <!-- Table -->

    <b-table
      striped
      :fields="headers"
      :items="listLogData.data.map((item) => ({ ...item, action: '' }))"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
      show-empty
      :busy="isLoading"
    >
      <!-- <template #cell(actions)="">
        <v-icon class="text-success" @click="editItem(item)">mdi-pencil</v-icon>
      </template> -->

      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        {{ footerTableInfo }}
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="getData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="listLogData.total"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="getData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ApiService from '@/common/api.service';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Bundle name', key: 'bundle_name' },
        { text: 'Message', key: 'message' }
        // { text: 'Action', key: 'actions' }
      ],
      rows: 0,
      filterList: [
        { value: 1, text: 'Bundle 1' },
        { value: 2, text: 'Bundle 2' }
      ],
      perPage: 10,
      currentPage: 1,
      filterValue: null,
      listLogData: {
        data: [],
        total: 0
      },
      isLoading: true
    };
  },
  created() {
    this.getData();
  },
  computed: {
    paramsListNotifyLog() {
      return { params: { limit: this.perPage, offset: this.currentPage } };
    },
    footerTableInfo() {
      const { currentPage, perPage, listLogData } = this;
      const rows = listLogData.total;
      return `Showing ${(currentPage - 1) * perPage + 1}-${currentPage * perPage > rows ? rows : currentPage * perPage}
        of ${rows}.`;
    }
  },
  methods: {
    handleChangeFilter() {},
    async getData() {
      this.isLoading = true;
      try {
        const dataRes = await ApiService.query('/payment/list-notify-logs', this.paramsListNotifyLog).then(
          (res) => res.data
        );
        // const dataResBd = await ApiService.query('/payment/bundle-name').then((res) => res.data);
        // console.log('getData -> dataResBd', dataResBd);
        const { status, data } = dataRes;
        if (status) {
          this.listLogData = data;
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: "Can't load data! Try again!",
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        this.isLoading = false;
      }
    },
    editItem: function () {
      // console.log('handleChangeFilter -> item', item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
